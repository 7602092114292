const OPT_RANGE = 1000
const FINISH_TO_NATIVE = 1//直接返回到上一页直接返回到上一页
const FINISH_AND_OPERATE = 2//结束并通知上一页操作结束并通知上一页操作，，如刷新如刷新
const OPERATE_RANGE = 3//只通知操作，不返回
const OPERATE_ONLY = 3

export const STATION_FINISHED = FINISH_AND_OPERATE * OPT_RANGE + 1 //建站结束
export const STANDARD_TASK_FINISHED =  OPERATE_RANGE * OPT_RANGE + 2 //标准化任务结束
export const FEEDBACK_FINISHED = FINISH_AND_OPERATE * OPT_RANGE + 3 //用户反馈结束
export const STATION_JOIN_FINISHED = FINISH_AND_OPERATE * OPT_RANGE + 4 //提交加盟
export const STATION_FIRST_VERIFIED = FINISH_AND_OPERATE * OPT_RANGE + 5 //初审通过、不通过
export const SIGNED_FINISHED =  FINISH_TO_NATIVE * OPT_RANGE + 6 //实名认证后签约任务结束
export const PREVIOUS_PAGE =  FINISH_TO_NATIVE * OPT_RANGE + 7 //返回到上一页
export const AUTH_SUBMIT =  OPERATE_RANGE * OPT_RANGE + 8 //实名认证过程每一步提交
export const APP_SHARE_WX =  OPERATE_RANGE * OPT_RANGE + 9 //实名认证过程每一步提交
export const STATION_SCORE_NEXT =  FINISH_AND_OPERATE * OPT_RANGE + 10 // 村情/站长评分
export const STATION_INFO_NEXT = OPERATE_ONLY * OPT_RANGE + 11 // 村情/站长下一步
export const FINISH_STATION_CHANGE = FINISH_AND_OPERATE * OPT_RANGE + 12 // 修改站点
export const CHANGE_AGENT_AUDIT = OPERATE_ONLY * OPT_RANGE + 13 // 修改站长审核 // "data: {""applyNo"":"""",""stationNo"":""""}"
export const MAP_NAVIGATION = OPERATE_ONLY * OPT_RANGE + 14 // 地图导航

//权限配置页面
export  var Home_Page = 'home'
export  var Mine_Page = 'mine'

// 公司主体
export const COMPANY_NAME = '江苏村口科技有限公司'
